import styled from 'styled-components'

export const Main = styled.div`
  height: 100%;
  font-size: 14px;
  line-height: 20px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Title = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`

export const Apps = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
`
