import React from 'react'
import PropTypes from 'prop-types'
import Table from '../table'
import AppDetails from '../appDetails'
import { Avatar, Tooltip, Button, ButtonType, ButtonSize, H4, Link, EmptyState, Stack } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getFullUrl } from '../../shared/utils'
import EnableFor from '../enableFor'
import { SCOPES } from '../../constants'
import { getFormattedDate } from '@lenses/utils'
import moment from 'moment'
import emptyImage from '@media/emptyState.svg'
import { CustomAppAlert } from './customAppAlert'
import isEqual from 'lodash/isEqual'
import * as Style from './style'

const EditButton = ({ app, openEditApplication }) =>
  <Tooltip label='Edit'>
    <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
      <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={() => openEditApplication(app)} icon='Edit' />
    </EnableFor>
  </Tooltip>

const getColumns = (onHide, isSmallScreen, openEditApplication, openPopupCopyAppData) =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: 150,
      Cell: ({ value: name, row: { id, imageUrl, category } }) => (
        <AppDetails
          id={id}
          name={name}
          category={category}
          imageUrl={imageUrl}
          component='Custom apps'
        />
      )
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value: url }) => <Link href={getFullUrl(url)} target='_blank'>{url}</Link>,
      width: 150
    },
    {
      Header: 'Potential identical app',
      accessor: 'possibleDuplicateApp',
      Cell: ({ value: possibleDuplicateApps }) => {
        const { name, imageUrl, id, category } = possibleDuplicateApps || {}

        return (
          possibleDuplicateApps ? <AppDetails
            id={id}
            name={name}
            category={category}
            imageUrl={imageUrl}
            component='Custom app - potential match'
          /> : null
        )
      },
      width: 170
    },
    {
      Header: 'Added by',
      accessor: 'addedBy',
      Cell: ({ value: addedBy }) => {
        const { firstName, lastName, photoUrl, id } = addedBy || {}
        const displayName = (firstName ? firstName + ' ' : '') + lastName || ''
        const content = <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
        return (
          <Tooltip label={displayName} hide={!firstName || !lastName}>
            {id
              ? <RelativeTeamUserLink idUser={id}>{content}</RelativeTeamUserLink>
              : content
            }
          </Tooltip>
        )
      },
      width: 100
    },
    {
      Header: 'Date added',
      accessor: 'creationTime',
      textValue: ({ value }) => value ? moment.utc(value).format('YYYY-MM-DD') : '-',
      Cell: ({ value }) => getFormattedDate({ date: value }),
      width: 150
    },
    {
      Header: '',
      sortable: false,
      className: Style.Actions.toString(),
      Cell: ({ row: { id, name, category, url, imageUrl, tags, description, possibleDuplicateApp } }) => {
        const { name: potentialAppName } = possibleDuplicateApp || {}
        const copyAppDataLabel = `Copy app data to ${potentialAppName || 'a native Torii app'}`

        return <Style.HoverActions>
          <Stack gap='space-0' direction='row'>
            <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
              <EditButton app={{ id, name, category, url, imageUrl, tags, description }} openEditApplication={openEditApplication} />
            </EnableFor>
            <Tooltip label='Hide'>
              <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
                <Button type={ButtonType.tertiary} onClick={() => onHide(id, name)} icon='EyeOff' />
              </EnableFor>
            </Tooltip>
            <Tooltip label={copyAppDataLabel}>
              <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]}>
                <Button type={ButtonType.tertiary} onClick={() => openPopupCopyAppData(id, name)} icon='FileSymlink' />
              </EnableFor>
            </Tooltip>
          </Stack>
        </Style.HoverActions>
      },
      width: 150
    },
    {
      accessor: 'category',
      show: false
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'description',
      show: false
    },
    {
      accessor: 'tags',
      show: false
    }
  ]

class CustomApps extends React.Component {
  state = {
    possibleDuplicateAppCount: 0
  }

  componentDidMount () {
    const { getCustomApps, idOrg } = this.props
    getCustomApps({ idOrg })
  }

  componentDidUpdate (prevProps) {
    const { apps } = this.props
    if (!isEqual(prevProps.apps, apps)) {
      const possibleDuplicateAppCount = apps.filter(app => !!app.possibleDuplicateApp).length
      this.setState({ possibleDuplicateAppCount })
    }
  }

  onHide = async (idApp, name) => {
    const { idOrg, changeAppHiddenState } = this.props
    Analytics.track('Clicked to hide application', {
      idApp,
      name
    })
    await changeAppHiddenState({ idOrg, idApp, isHidden: true })
  }

  renderNoApps = () => {
    return (
      <EmptyState
        image={<img src={emptyImage} alt='No custom applications' />}
        description='No custom applications were added yet'
      />
    )
  }

  openAddApplication = () => {
    const { toggleAddApplication } = this.props
    toggleAddApplication({ isAddApplicationOpen: true, customApp: true, openFrom: 'Add custom application' })
  }

  openEditApplication = (app) => {
    const { toggleAddApplication } = this.props
    Analytics.track('Clicked to open popup: edit custom application', {
      'App id': app.id,
      'App name': app.name
    })
    toggleAddApplication({ isAddApplicationOpen: true, customApp: true, editMode: true, app })
  }

  openPopupCopyAppData = (idApp, name) => {
    const { apps, companyName, togglePopupCopyAppData } = this.props
    const selectedApp = apps.find(app => app.id === idApp)

    if (selectedApp) {
      togglePopupCopyAppData({
        isOpen: true,
        idApp,
        appName: name,
        possibleDuplicateApp: selectedApp.possibleDuplicateApp
      })

      Analytics.track('Click on copy app data button', {
        'Company name': companyName,
        'App name': name
      })
    }
  }

  render () {
    const { apps, loading, tableKey, isSmallScreen } = this.props
    const addApp = <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}><Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.openAddApplication} label='+ Add application' /></EnableFor>
    const columns = getColumns(this.onHide, isSmallScreen, this.openEditApplication, this.openPopupCopyAppData)
    return <Table
      tableKey={tableKey}
      emptyStateMessage={this.renderNoApps()}
      data={apps}
      alertBoxComponent={this.state.possibleDuplicateAppCount > 0 && <CustomAppAlert appCount={this.state.possibleDuplicateAppCount} />}
      columns={columns}
      header={<H4>Custom Applications</H4>}
      loading={loading}
      extraHeaderComponent={addApp}
      pageSize={50}
      clientPaging
    />
  }
}

CustomApps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    url: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    creationTime: PropTypes.string.isRequired,
    addedBy: PropTypes.object.isRequired
  })),
  tableKey: PropTypes.string.isRequired,
  togglePopupCopyAppData: PropTypes.func,
  companyName: PropTypes.string.isRequired
}

export default CustomApps
