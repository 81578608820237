import React from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import { Icon } from '@toriihq/design-system'
import SimilarApp from '../similarApp'
import * as Style from './style'

class SimilarApps extends React.Component {
  render () {
    const { loading, similarApps } = this.props
    if (loading || similarApps.length === 0) {
      return null
    }

    if (similarApps.length === 0) {
      return null
    }
    const sortedSimilarApps = orderBy(similarApps, ['desc'])

    return (
      <Style.Main>
        <Style.TitleWrapper>
          <Icon name='Applications' color='secondary' />
          <Style.Title>SIMILAR PRODUCTS</Style.Title>
        </Style.TitleWrapper>
        <Style.Apps>
          {sortedSimilarApps.map((app, i) => (
            <SimilarApp
              key={app.id}
              name={app.name}
              imageUrl={app.imageUrl}
              targetUrl={`/app/${app.id}`}
              last={i === sortedSimilarApps.length - 1}
              disabled={app.disabled}
            />
          ))}
        </Style.Apps>
      </Style.Main>
    )
  }
}

SimilarApps.propTypes = {
  similarApps: PropTypes.array,
  loading: PropTypes.bool
}

SimilarApps.defaultProps = {
  similarApps: []
}

export default SimilarApps
