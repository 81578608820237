import styled from 'styled-components'

export const Actions = styled.div`
  text-align: right;
`

export const HoverActions = styled.div`
  opacity: 0;
  transition: opacity 0.2s;
  .rt-tr:hover & {
    opacity: 1;
  }
`
