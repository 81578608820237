import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { stateColors } from '@shared/style/colors'
import { Icon } from '@toriihq/design-system'
import * as Style from './style'

const ColorPicker = ({ color, onChange, disabled, postOpen, postClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    if (!disabled) {
      setIsOpen(prevIsOpen => !prevIsOpen)
      postOpen && postOpen(!isOpen)
    }
  }

  const handleClose = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
    postClose && postClose()
  }

  return (
    <Style.Container>
      <Style.Swatch disabled={disabled} onClick={handleOpen}>
        <Style.ColorDot color={color} />
        <Icon name='ChevronDown' color='inherit' />
      </Style.Swatch>
      {isOpen ? (
        <Style.Popover>
          <Style.Cover onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={color => onChange(color.hex)}
            presetColors={stateColors}
            disableAlpha
          />
        </Style.Popover>
      ) : null}
    </Style.Container>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  postOpen: PropTypes.func,
  postClose: PropTypes.func
}

ColorPicker.defaultProps = {
  color: '#FFFFFF',
  disabled: false,
  postOpen: () => {},
  postClose: () => {}
}

export default ColorPicker
